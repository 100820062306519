<ion-card [class.pending]="request.request_status == 'pending'" tappable [attr.dir]="translateService.direction()">
  <ion-card-content>
    <h1>{{ request.request_position_title }}</h1>
    <div class="requested-by" *ngIf="request.contact" [innerHTML]="'requested_by_value' | translate:{value: request.contact.contact_name}"></div>
    <div class="created-at">{{ "on" | translate }} {{ toDate(request.request_updated_datetime) | date : "MMM d, yyyy, hh:mm a": undefined: translateService.currentLang }}</div>

    <ion-badge *ngIf="request.nationality">
      {{ translateService.langContent(request.nationality.country_nationality_name_en, request.nationality.country_nationality_name_ar) }}
    </ion-badge>

    <ion-badge *ngIf="request.gender == 0">{{ "Any gender" | translate }}</ion-badge>
    <ion-badge *ngIf="request.gender == 1">{{ "Male" | translate }}</ion-badge>
    <ion-badge *ngIf="request.gender == 2">{{ "Female" | translate }}</ion-badge>
    <ion-badge *ngIf="request.gender == 3">{{ "Other gender" | translate }}</ion-badge>

    <ion-list lines="none">
      <ion-item *ngIf="request.request_location">
        <div tabindex="0"></div>
        <ion-icon class="icon" src="assets/images/ic_location.svg"></ion-icon>
        <label>{{request.request_location}}</label>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-icon class="icon" src="assets/images/ic_suitcase-light.svg"></ion-icon>
        <label>{{"ppl_count" | translate: {value: request.request_number_of_employees} }}<span *ngIf="request.request_position_type == 1">,
          {{"Full-time" | translate}}</span>
          <span *ngIf="request.request_position_type == 2">, {{"Part-time" | translate}}</span>
        </label>
      </ion-item>
      <ion-item *ngIf="request.request_compensation">
        <div tabindex="0"></div>
        <ion-icon class="icon" src="assets/images/componsation.svg"></ion-icon>
        <label>{{request.request_compensation}}</label>
      </ion-item>
    </ion-list>

    <ion-grid *ngIf="showStatus && request.stats">
      <ion-row>
        <ion-col>{{request.stats.invited}} <br/><span>{{"Invited" | translate}}</span></ion-col>
        <ion-col>{{request.stats.suggested}} <br/><span>{{"Suggested" | translate}}</span></ion-col>
        <ion-col>{{request.stats.accepted}} <br/><span>{{"Accepted" | translate}}</span></ion-col>
        <ion-col>{{request.stats.rejected}} <br/><span>{{"Rejected" | translate}}</span></ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
