<!------------------ text-list -------------------->

<div class="text-list" *ngIf="loading && type == 'text-list'">
  <ion-list>
    <ion-item><div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
  </ion-list>
</div>

<div class="candidate-list" *ngIf="loading && type == 'candidate-list'">
  <ion-list>
    <ion-item><div tabindex="0"></div>
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>
</div>

<div class="text-list-view" *ngIf="loading && type == 'text-list-view'">
  <ion-list>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5><ion-skeleton-text></ion-skeleton-text></h5>
        <p><ion-skeleton-text></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5><ion-skeleton-text></ion-skeleton-text></h5>
        <p><ion-skeleton-text></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5><ion-skeleton-text></ion-skeleton-text></h5>
        <p><ion-skeleton-text></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5><ion-skeleton-text></ion-skeleton-text></h5>
        <p><ion-skeleton-text></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5><ion-skeleton-text></ion-skeleton-text></h5>
        <p><ion-skeleton-text></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5><ion-skeleton-text></ion-skeleton-text></h5>
        <p><ion-skeleton-text></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
  </ion-list>
</div>

<div class="candidate-detail" *ngIf="loading && type == 'candidate-detail'">
  <ion-item-group>
    <ion-item><div tabindex="0"></div>
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
        <p><ion-skeleton-text animated></ion-skeleton-text></p>
        <p><ion-skeleton-text animated></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item target='_blank'>
      <div tabindex="0"></div>
      <ion-icon name="call" slot="start"></ion-icon>
      <ion-label><ion-skeleton-text animated></ion-skeleton-text></ion-label>
    </ion-item>
    <ion-item target='_blank'>
      <div tabindex="0"></div>
      <ion-icon name="mail" slot="start"></ion-icon>
      <ion-label><ion-skeleton-text animated></ion-skeleton-text></ion-label>
    </ion-item>

    <ion-item><div tabindex="0"></div>
      <ion-icon name="pin" slot="start"></ion-icon>
      <ion-label><ion-skeleton-text animated></ion-skeleton-text></ion-label>
    </ion-item>

    <ion-item-divider color="light">{{"Store Assignment" | translate}}</ion-item-divider>
    <ion-item><div tabindex="0"></div>
        <ion-skeleton-text animated></ion-skeleton-text>
    </ion-item>

    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-item>
    <ion-item-divider color="light">{{"Personal" | translate}}</ion-item-divider>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5>{{"Age" | translate}}</h5>
        <p><ion-skeleton-text animated></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5>{{"Nationality" | translate}}</h5>
        <p><ion-skeleton-text animated></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5>{{"University" | translate}}</h5>
        <p><ion-skeleton-text animated></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item-divider color="light">{{"Civil ID" | translate}}</ion-item-divider>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5>{{"Civil ID Number" | translate}}</h5>
        <p><ion-skeleton-text animated></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5>{{"Birth date" | translate}}</h5>
        <p><ion-skeleton-text animated></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5>{{"Expiry Date" | translate}}</h5>
        <p><ion-skeleton-text animated></ion-skeleton-text></p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5>{{"ID Photo Front" | translate}}</h5>
        <p>
          <a target='_blank'>
            <ion-skeleton-text animated></ion-skeleton-text>
          </a>
        </p>
      </ion-label>
    </ion-item>
    <ion-item><div tabindex="0"></div>
      <ion-label>
        <h5>{{"ID Photo Back" | translate}}</h5>
        <p>
          <a target='_blank'>
            <ion-skeleton-text animated></ion-skeleton-text>
          </a>
        </p>
      </ion-label>
    </ion-item>
  </ion-item-group>
</div>

<div class="transfer-view" *ngIf="loading && type == 'transfer-view'">
  <div class="ion-text-center ion-padding">
    <h2 class="ion-text-center">
      <ion-skeleton-text animated></ion-skeleton-text>
    </h2>
    <div><ion-skeleton-text animated></ion-skeleton-text></div>
  </div>

  <div>
    <ion-grid>
      <ion-row>
        <ion-col size-lg="3" size-md="3" size-sm="6" size-xs="6" class="ion-align-self-center">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
        <ion-col size-lg="3" size-md="3" size-sm="6" size-xs="6"  class="ion-align-self-center">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
        <ion-col size-lg="3" size-md="3" size-sm="6" size-xs="6"  class="ion-align-self-center">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
        <ion-col size-lg="3" size-md="3"  size-sm="6" size-xs="6" class="ion-align-self-center">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!--Invoices -->
  <div class="invoices">
    <h4>{{"Invoices" | translate}}</h4>
    <ion-list>
      <ion-item><div tabindex="0"></div>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
      <ion-item><div tabindex="0"></div>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
    </ion-list>
  </div>

  <!-- Receipts -->
  <div class="receipts">
    <h4>{{"Receipts" | translate}}</h4>
    <ion-list>
      <ion-item><div tabindex="0"></div>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
      <ion-item><div tabindex="0"></div>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
    </ion-list>
  </div>

  <!-- Transfer Details made to each Candidate -->
  <ion-list>
    <!-- store_name pipe returns the transferCandidates grouped by store as object of following format: -->
    <!-- {name: string, resources: TransferCandidate[]} -->
    <ion-item-group>
      <ion-item-divider><b><ion-skeleton-text animated></ion-skeleton-text></b></ion-item-divider>

      <ion-item><div tabindex="0"></div>
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h2><ion-skeleton-text animated></ion-skeleton-text></h2>

          <p><ion-skeleton-text animated></ion-skeleton-text></p>
          <p><ion-skeleton-text animated></ion-skeleton-text></p>
          <p><b><ion-skeleton-text animated></ion-skeleton-text></b></p>
        </ion-label>
      </ion-item>
    </ion-item-group>
  </ion-list>
</div>

<!------------------- candidate-filter ------------------------>

<div class="candidate-filter" *ngIf="loading && type == 'candidate-filter'">
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
</div>

<!------------------------ candidate -------------------->

<div *ngIf="loading && type == 'candidate'" #container>
  <ion-card class="candidate">
    <ion-card-content>

      <div class="background-masker image"><ion-skeleton-text animated></ion-skeleton-text></div>

      <h2 class="background-masker name"><ion-skeleton-text animated></ion-skeleton-text></h2>

      <small class="city">
        <div class="background-masker city-location"><ion-skeleton-text animated></ion-skeleton-text></div>
      </small>

      <div class="clearfix"><ion-skeleton-text animated></ion-skeleton-text></div>

      <p class="background-masker objective"><ion-skeleton-text animated></ion-skeleton-text></p>

      <hr />

      <p class="background-masker work"><ion-skeleton-text animated></ion-skeleton-text></p>

    </ion-card-content>
  </ion-card>

  <ion-card class="candidate">
    <ion-card-content>

      <div class="background-masker image"><ion-skeleton-text animated></ion-skeleton-text></div>

      <h2 class="background-masker name"><ion-skeleton-text animated></ion-skeleton-text></h2>

      <small class="city">
        <div class="background-masker city-location"><ion-skeleton-text animated></ion-skeleton-text></div>
      </small>

      <div class="clearfix"><ion-skeleton-text animated></ion-skeleton-text></div>

      <p class="background-masker objective"><ion-skeleton-text animated></ion-skeleton-text></p>

      <hr />

      <p class="background-masker work"><ion-skeleton-text animated></ion-skeleton-text></p>

    </ion-card-content>
  </ion-card>
</div>

<!--------------------------- request-list -------------------->

<div class="request-list" *ngIf="loading && type == 'request-list'">

  <ion-card>
    <ion-card-content>
      <h1>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h1>
      <h3>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h3>

      <p class="txt-additional-info">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </p>
      <small class="time">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </small>
      <div class="content-footer">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <h1>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h1>

      <h3>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h3>

      <p class="txt-additional-info">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </p>
      <small class="time">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </small>
      <div class="content-footer">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <h1>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h1>
      <h3>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h3>

      <p class="txt-additional-info">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </p>
      <small class="time">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </small>
      <div class="content-footer">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </div>
    </ion-card-content>
  </ion-card>
</div>


<!--------------------------- candidate-view -------------------->

<div class="candidate-view" *ngIf="loading && type == 'candidate-view'">

  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
        <div class="left-side">
          <ion-thumbnail>
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-thumbnail>

          <h2 class="candidate-name">
            <ion-skeleton-text animated></ion-skeleton-text>
          </h2>

          <h4 class="candidate-name">
            <ion-skeleton-text animated></ion-skeleton-text>
          </h4>

          <div class="birth-date">

            <span class="txt-born-on">
              <ion-skeleton-text animated></ion-skeleton-text>
            </span>
            <ion-skeleton-text class="txt-birth-date" animated></ion-skeleton-text>

          </div>

          <ion-skeleton-text class="job-status" animated></ion-skeleton-text>

          <div class="objective">
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
          </div>
        </div>
      </ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
        <div class="right-side details">

          <ion-item class="video">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>

          <ion-item class="resume">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>

          <ion-item class="skills">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>

          <ion-item class="experiences">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>

          <ion-item class="area">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>

          <ion-item class="nationality">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>

          <ion-item class="gender">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>

          <ion-item class="university">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>

          <ion-item class="driving-license">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>

          <ion-item class="mother-nationality">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>

          <ion-item class="id-number">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>

          <ion-item class="civil-front">
            <div tabindex="0"></div>

            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
              <span>
                <ion-skeleton-text class="civil-id-image" animated></ion-skeleton-text>
              </span>
            </ion-label>
          </ion-item>

          <ion-item class="civil-back">
            <div tabindex="0"></div>

            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
              <span>
                <ion-skeleton-text class="civil-id-image" animated></ion-skeleton-text>
              </span>
            </ion-label>
          </ion-item>

          <ion-item class="created-at">
            <div tabindex="0"></div>
            <ion-label>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
