<ion-header [class.add-border]="borderLimit" class="clear-header">
  <ion-toolbar class="desktop-view">
      <ion-buttons slot="start">
        <ion-back-button mode="md" icon="assets/images/icon-back.svg" defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-title>{{"Team List" | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="desktop-view">
    <ion-list class="contact-list">

      <ion-searchbar [(ngModel)]="query" *ngIf="contactList && contactList.length > 2" (ionChange)="filter($event)" placeholder="Search by name / phone / email" debounce="100"></ion-searchbar>

      <ion-item *ngFor="let contact of contactList">

        <div tabindex="0"></div>

        <ion-label>
          <h4>
            <span class="you" *ngIf="contact.contact_email == authService.email">You&nbsp;•&nbsp;</span>
            {{ contact.contact.contact_name }}
          </h4>

          <p *ngIf="contact.contact_position">
            {{ contact.contact_position }}
          </p>

          <p *ngIf="contact.contact.contact_email">
            <a (click)="doNothing($event)" href="mailto:{{ contact.contact.contact_email }}">
              <ion-icon name="mail-outline"></ion-icon> {{ contact.contact.contact_email }}
            </a>
          </p>

          <p>
            <a (click)="doNothing($event)" href="mailto:{{ contactEmail.email_address }}"
                *ngFor="let contactEmail of contact.contactEmails">
              <ion-icon name="mail-outline"></ion-icon> {{ contactEmail.email_address }}
            </a>
          </p>

          <p>
            <a (click)="doNothing($event)" href="tel:{{ contactPhone.phone_number }}"
                *ngFor="let contactPhone of contact.contactPhones">
              <ion-icon name="call-outline"></ion-icon> {{ contactPhone.phone_number }}
            </a>
          </p>

          <ion-badge color="danger" *ngIf="contact.allow_access && !contact.contact.contact_email">
            {{"Login email need to update" | translate}}
          </ion-badge>

          <ion-badge slot="start" color="light" *ngIf="contact.contact.contact_receive_email">
            <ion-icon name="checkmark-outline"></ion-icon>
            {{"Receive email" | translate}}
          </ion-badge>

          <!--
          <ion-badge slot="start" color="light" *ngIf="contact.contact.contact_receive_notification">
            <ion-icon name="checkmark-outline"></ion-icon> Receive notification
          </ion-badge>-->

        </ion-label>
      </ion-item>

      <ion-infinite-scroll *ngIf="currentPage < pageCount" (ionInfinite)="doInfinite($event)">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>

    </ion-list>
  </div>

</ion-content>
