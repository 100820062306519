import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
//services
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(public authService: AuthService) { 
    mixpanel.init(environment.mixpanelKey);
  }

  /**
   * register user
   * @param id 
   * @param params 
   */
  user(id, params) {
    
    //segment

    if(window.analytics)
      window.analytics.identify(id, params);

    //mixpanel 

    mixpanel.identify(id);

    mixpanel.people.set(params);
  }

  /**
   * page event
   * @param name 
   */
  page(name) {
    if(window.analytics)
      window.analytics.page(name);

    /*mixpanel.track("Page View", {
      "name": name
    });*/
    
    const params = {
      language : this.authService.language_pref,
      channel : "Company Web App",
    }

    mixpanel.track_pageview({"page": name, ...params });

    // track the elapsed time between a page viewed and page exit
    //call time_event with page_viewed event
    mixpanel.time_event("page_exit");
  }

  /**
   * custom event
   * @param eventName 
   * @param params 
   */
  track(eventName, params) {
    
    params.language = this.authService.language_pref; 
    params.channel = "Company Web App"; 
    
    if(window.analytics)
      window.analytics.track(eventName, params);

    mixpanel.track(eventName, params);
  }

  refresh() {
    mixpanel.reset();
  }
}
