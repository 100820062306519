<ion-header [class.add-border]="borderLimit" class="clear-header">
  <ion-toolbar>

      <ion-buttons slot="start">
        <ion-back-button mode="md" icon="assets/images/icon-back.svg"></ion-back-button>
      </ion-buttons>

  </ion-toolbar>
</ion-header>

<ion-content [scrollEvents]="true" (ionScroll)="logScrolling($event)">

  <div class="desktop-view">

    <div class="title-txt">
      {{"Please type in their email address to send the invite" | translate}}
    </div>

    <form [formGroup]="form" *ngIf="form" (ngSubmit)="submitForm()">
      <ion-list>
        <ion-item lines="none">
          <ion-input inputmode="email" enterkeyhint="Submit" type="email" placeholder="email@email.com" #input formControlName="email_to_invite">
          </ion-input>
        </ion-item>
      </ion-list>
    </form>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-no-padding desktop-view">
    <div class="desktop-view">
      <ion-button *ngIf="form" class="submit-btn" [disabled]="!form || !form.valid || loading"
        (click)="submitForm()">
        <ion-spinner *ngIf="loading"></ion-spinner>
        <span *ngIf="!loading">{{"Send Invite" | translate}}</span>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
