import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
// services
import { AuthHttpService } from './authhttp.service';
// models
import { Request } from 'src/app/models/request';


@Injectable({
  providedIn: 'root'
})
export class CompanyRequestService {

  private companyRequestEndpoint = '/requests';

  constructor(private authhttp: AuthHttpService) { }

  /**
   * List all requests
   * @returns {Observable<any>}
   */
  list(companyID: number): Observable<any> {
    const url = this.companyRequestEndpoint + '?company_id=' + companyID +
      '&expand=nationality,company,staff,requestCreatedBy,requestUpdatedBy,contact,requestActivities,requestActivities.staff';
    return this.authhttp.get(url);
  }

  /**
   * List all requests with page
   * @returns {Observable<any>}
   */
  listWithPagination(page: number, urlParams: string = ''): Observable<any> {
    const url = this.companyRequestEndpoint + '?page=' + page + urlParams +
      '&expand=nationality,staff,requestCreatedBy,requestUpdatedBy,contact,company,company.companyContact,requestActivities,requestActivities.staff,invitations,stats';
    return this.authhttp.getRaw(url);
  }

  /**
   * requests started/active but not by login user
   */
  listActiveRequests(page: number, filterParams = '') : Observable<any> {
    let url = this.companyRequestEndpoint + '/active?page=' + page + filterParams;
    return this.authhttp.getRaw(url);
  }

  /**
   * list candidate applications
   * @param request_uuid 
   * @param page 
   * @returns 
   */
  listApplications(request_uuid: string, page: number) : Observable<any> {
    let url = this.companyRequestEndpoint + '/applications/'+ request_uuid +'?expand=candidate,requestInterview&page=' + page;
    return this.authhttp.getRaw(url);
  }

  /**
   * request to schedule interview
   * @param params 
   * @returns 
   */
  requestInterview(params): Observable<any> {
    return this.authhttp.post(this.companyRequestEndpoint + '/request-interview', params);
  }

  /**
   * create request
   * @param model
   */
  create(model: Request): Observable<any> {
    return this.authhttp.post(this.companyRequestEndpoint, {
      position_type: model.request_position_type,
      position_title: model.request_position_title,
      number_of_employees: model.request_number_of_employees,
      additional_info: model.request_additional_info,
      compensation: model.request_compensation,
      location: model.request_location,
      job_description: model.request_job_description,
      requestSkills: model.requestSkills,
      nationality_id: model.nationality_id,
      gender: model.gender
    });
  }

  /**
   * cancel request
   * @param model
   */
  cancel(model: Request): Observable<any> {
    const url = `${this.companyRequestEndpoint}/cancel/${model.request_uuid}`;
    return this.authhttp.patch(url, {
      feedback: model.request_feedback
    });
  }

  /**
   * deliver request
   * @param model
   */
  deliver(model: Request): Observable<any> {
    const url = `${this.companyRequestEndpoint}/deliver/${model.request_uuid}`;
    return this.authhttp.patch(url, {
      feedback: model.request_feedback
    });
  }

  /**
   * update request
   * @param model
   */
  update(model: Request): Observable<any> {
    return this.authhttp.patch(`${this.companyRequestEndpoint}/${model.request_uuid}`, {
      position_type: model.request_position_type,
      position_title: model.request_position_title,
      number_of_employees: model.request_number_of_employees,
      additional_info: model.request_additional_info,
      compensation: model.request_compensation,
      location: model.request_location,
      job_description: model.request_job_description,
      requestSkills: model.requestSkills,
      nationality_id: model.nationality_id,
      gender: model.gender
    });
  }

  /**
   * delete request
   * @param model
   */
  delete(model: Request): Observable<any> {
    return this.authhttp.delete(`${this.companyRequestEndpoint}/${model.request_uuid}`);
  }

  /**
   * view request
   * @param id
   */
  view(id): Observable<any> {
    const url = this.companyRequestEndpoint + '/' + id +
      '?expand=nationality,requestSkills,requestCreatedByContact,requestUpdatedByContact,requestCreatedBy,requestUpdatedBy,contact,company,company.companyContact,requestActivities,requestActivities.staff,invitations,invitations.candidate,invitations.suggestion,stories';
    return this.authhttp.get(url);
  }

  /**
   * add activity
   * @param params
   */
  addActivity(params) : Observable<any> {
    let url = this.companyRequestEndpoint + '/add-activity';
    return this.authhttp.post(url, params);
  }

  /**
   * request count
   * @returns {Observable<any>}
   */
  requestCount(): Observable<any> {
    return this.authhttp.get(this.companyRequestEndpoint + '/count');
  }

  /**
   * check if request updated
   * @param request_uuid
   */
  isRequestUpdated(request_uuid) : Observable<any> {
    let url = this.companyRequestEndpoint + '/is-request-updated/' + request_uuid;
    return this.authhttp.get(url);
  }
}
